import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import ButtonLink from './links/button-link';
import ScrollIndicator from './scroll-indicator';
import { mq } from './_shared/media';
import { StyledSection } from './_shared/styled-section';
import bgUrl from '../images/bg.png';

const BackgroundHero = styled.div`
  min-height: calc(100vh - 2 * var(--header-height));
  min-width: 100%;
  position: absolute;
  background: url(${bgUrl});
  background-repeat: no-repeat;
  background-size: auto 100%;
  opacity: 0.15;
  z-index: -9;
  ${mq.gt.sm} {
    min-height: 100vh;
  }
`;
const StyledHeroSection = styled(StyledSection)`
  min-height: calc(100vh - 2 * var(--header-height));
  position: relative;
  ${mq.gt.sm} {
    min-height: calc(100vh - var(--header-height));
  }
`;
const StyledIntroduction = styled.div`
  color: var(--primary-color);
  font-weight: 600;
  font-size: 40px;
`;
const StyledAuthor = styled.h1`
  margin-left: -4px !important;
  font-size: 40px;
  font-weight: 600;
  line-height: 1.1;
  margin: 0;
  word-break: break-word;

  ${mq.gt.xs} {
    font-size: 80px;
  }
`;
const StyledTagline = styled.h2`
  margin-left: -4px !important;
  font-size: 30px;
  line-height: 1.1;
  margin: 0 0 20px 0;
  font-weight: 200;
  word-break: break-word;

  ${mq.gt.xs} {
    font-size: 40px;
  }
`;
const StyledDescription = styled.div`
  margin-top: 0.5rem;
  width: 100%;
  max-width: 500px;
`;

const Hero = ({ data }) => {
  const { introduction, author, tagline, description, ctaLink, ctaLabel } = data;

  return (
    <div>
      <BackgroundHero />
      <StyledHeroSection>
        <StyledIntroduction>{introduction}</StyledIntroduction>
        <StyledAuthor>{author}</StyledAuthor>
        <StyledTagline>{tagline}</StyledTagline>
        {/* <StyledDescription dangerouslySetInnerHTML={{ __html: description }} /> */}
        <ButtonLink label={ctaLabel} link={ctaLink} />
        {/* <ScrollIndicator /> */}
      </StyledHeroSection>
    </div>
  );
};

Hero.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Hero;
